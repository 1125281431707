import React from "react";

import "./AppBg.css";

const AppBg = () => {
  return (
    <div className="app-bg">
      <img
        className="cloud-1"
        src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1722102855/clouds1_as4j10.png"
        alt="cloud 1"
      />
      <div className="ellipse ellipse-1"></div>
      <div className="ellipse ellipse-2"></div>
    </div>
  );
};

export default AppBg;
